import React from 'react'
import { HiX } from 'react-icons/hi'
import FeaturedCard from '../FeaturedCard'
function MapDetailCard(props) {
  return (
    <div style={{ width: '-webkit-fill-available', maxWidth: '400px', margin: '0px 10px', zIndex: '999', position: 'fixed', bottom: '50px' }}>
      <div onClick={() => { props.setIsDetailCardShown(false) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '25px', height: '50px', width: '50px', backgroundColor: '#ffffff80', padding: '0px 5px', position: 'absolute', right: '10px', top: '10px' }}>
        <HiX style={{ fontSize: '30px', color: '#000000' }} />
      </div>
      <FeaturedCard data={props.data} />
    </div>
  )
}

export default MapDetailCard   