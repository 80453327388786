import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { database } from "../config";

export default function ReadReviews(props) {

  //snapshots
  const [reviews, setReviews] = useState([]);


  //get listing data
  useEffect(() => {
    database
      .ref("Reviews")
      .on("value", (snapshot) => {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childKey = childSnapshot.key;
          var data = childSnapshot.val();
          if (props.propertyKey === data.propertyKey) {
            items.push({
              key: data.propertyKey,
              name: data.name,
              review: data.review,
              stars: data.stars,
            });
          }
        });
        setReviews(items);
      });
  }, []);
  function PlotStars({ stars }) {
    switch (Number(stars)) {
      case 1:
        return (
          <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
        )
      case 2:
        return (
          <>
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
          </>
        )
      case 3:
        return (
          <>
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
          </>
        )
      case 4:
        return (
          <>
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
          </>
        )
      case 5:
        return (
          <>
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
          </>
        )
      default:
        return (
          <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />
        )
    }
  }

  return (
    <>
      {reviews.length > 0 ? <h4 className="mt-1">Reviews</h4> : <h4 className="mt-1">No Reviews</h4>}

      <Container >
        <Row>
          {reviews.map((data, id) => (
            <Col sm={12} md={12} lg={12} key={uuidv4()} style={{ padding: '0px' }}>
              <Card className="mt-2">
                <Card.Body>
                  <Card.Title className="text-dark" style={{ textTransform: 'capitalize' }} >{data.name}</Card.Title>
                  <PlotStars stars={data.stars} />
                  <Card.Text className="text-dark">
                    {data.review}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <br />
      <br />
    </>
  );
}
