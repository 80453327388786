import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import { HiChat, HiHeart, HiOutlineHeart } from 'react-icons/hi'
import { database } from "../config";
function FeaturedCard(props) {
    const [likes, setLikes] = useState(0)
    const [comments, setComments] = useState(0)

    useEffect(() => {
        database.ref('car_likes').child(props.data.key).on('value', (snapshot) => {
            snapshot.val() && setLikes(snapshot.val().like);
        })
        database.ref("comments").child(props.data.key).on("value", (snapshot) => {
            snapshot.val() && setComments(snapshot.numChildren());
        })
        return () => {
            setComments(0)
            setLikes(0)
        }
    }, [props])

    return (
        <div className="featured_card_container" >
            <Link to={{ pathname: '/car', search: `?${props.data.key}`, state: { fromDashboard: true } }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <img
                        src={props.data.imageOneURL}
                        style={{ borderRadius: '10px 10px 0px 0px', height: '200px', width: '100%' }}
                    />
                    <div style={{ width: '100%', color: '#000', padding: '15px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ flex: '.6', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                <span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>$ {Number(props.data.sellingPrice).toLocaleString('en')}</span>
                                <span><FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon> {String(props.data.address).slice(0, 20)} </span>
                            </div>
                            <div style={{ marginRight: '5px', flex: '.2', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '25px', height: 'min-content', maxHeight: '50px', width: 'min-content', maxWidth: '100px', backgroundColor: '#42A5F530', padding: '7px 10px' }}>
                                <HiChat style={{ fontSize: '30px', color: '#42A5F5', marginRight: '5px' }} />
                                {comments}
                            </div>
                            <div style={{ flex: '.2', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '25px', height: 'min-content', maxHeight: '50px', width: 'min-content', maxWidth: '100px', backgroundColor: '#EF535030', padding: '7px 10px' }}>
                                <HiHeart style={{ fontSize: '30px', color: '#EF5350', marginRight: '5px' }} />
                                {likes}
                            </div>
                        </div>
                        <hr style={{ margin: '10px 0px' }} />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '12px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#42A5F530' }}>
                                <img src='https://www.cars24.com/js/7b1448ca4a28578b1810e746ec1e2365.svg' />{props.data.transmission}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#42A5F530' }}>
                                <img src='https://www.cars24.com/js/eb2a3896599f11d4c9128db3b7ebd9dc.svg' /> {props.data.fuel}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#42A5F530' }}>
                                <img src='https://www.cars24.com/js/77dfe6869b0934d82728252b09358190.svg' /> {props.data.miles}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default FeaturedCard