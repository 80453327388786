import React, { useEffect, useState, useRef } from "react";
import { database } from "../../config";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from 'mapbox-gl-geocoder'
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import TopBar from "../TopBar";
import { useLocation } from "react-router-dom";
import MapDetailCard from "./MapDetailCard";
import { categories } from '../CategoriesSection'
import { ToastContainer, toast } from "react-toastify";
const CreateMap = () => {
  mapboxgl.accessToken = 'pk.eyJ1IjoibWVudXRvayIsImEiOiJjbGMzeG1kNHgwbm9lNDFuemkzaXduazgxIn0.alLoPOMYiPcZtHmA9EaLZQ';

  const [isDetailCardShown, setIsDetailCardShown] = useState(false)
  const [currentProperty, setCurrentProperty] = useState(null)
  const [filterCategory, setFilterCategory] = useState("")
  const [filterCompany, setFilterCompany] = useState("")
  const [companies, setCompanies] = useState([])
  const [allCars, setAllCars] = useState([])
  const location = useLocation();
  const map = useRef(null);

  useEffect(() => {
    if (location.state?.category || location.state?.company) {
      location.state?.category && setFilterCategory(location.state?.category)
      location.state?.company && setFilterCompany(location.state?.company)
    }
    database.ref("companies").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          items.push(childData);
        });
        setCompanies(items)
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [])


  useEffect(() => {
    if (map.current) return; // initialize map only once 
    const defaultLocation = location.state?.lng ? [
      location.state.lng,
      location.state.lat,
    ] : [4.380197, 50.826758]
    const defaultZoom = location.state?.lng ? 10 : 1
    map.current = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/streets-v11",
      center: defaultLocation,
      zoom: defaultZoom,
      projection: 'globe'
    });
    map.current.on('style.load', () => {
      map.current.setFog({
        color: 'rgb(186, 210, 235)', // Lower atmosphere
        'high-color': 'rgb(0, 0, 0)', // Upper atmosphere
        'horizon-blend': 0.01, // Atmosphere thickness (default 0.2 at low zooms)
        'space-color': 'rgb(11, 11, 25)', // Background color
        'star-intensity': 0.1 // Background star brightness (default 0.35 at low zoooms )
      });
    });
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    });
    map.current.addControl(geolocate, "top-left")
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: true,
      hideOnSelect: true,
    });
    map.current.addControl(geocoder);
    map.current.on('click', (e) => {
      map.current.flyTo({
        center: e.lngLat
      });
    });
  }, []);

  useEffect(() => {
    if (allCars.length === 0) {
      database.ref("cars").on("value", (snapshot) => {
        const items = [];
        if (snapshot.val() !== null) {
          snapshot.forEach((childSnapshot) => {
            var childKey = childSnapshot.key;
            var data = childSnapshot.val();
            const marker = new mapboxgl.Marker({ color: 'red' })
              .setLngLat(data.lngLat)
              .addTo(map.current)
            marker.getElement().addEventListener('click', () => {
              setCurrentProperty({ key: childKey, ...data })
              setIsDetailCardShown(true)
            });
            items.push({ key: childKey, ...data, marker: marker });
          });
          setAllCars(items)
        }
      })
    }
    map && allCars.map((item) => {
      if (filterCategory !== "" && filterCompany !== "") {
        if (item.category !== filterCategory || item.company !== filterCompany) {
          item.marker.remove()
        } else if (item.category === filterCategory && item.company === filterCompany) {
          item.marker.addTo(map.current)
        }
      } else {
        if (filterCategory !== "" && filterCompany === "") {
          if (item.category !== filterCategory) {
            item.marker.remove()
          } else {
            item.marker.addTo(map.current)
          }
        } else if (filterCategory === "" && filterCompany !== "") {
          if (item.company !== filterCompany) {
            item.marker.remove()
          } else {
            item.marker.addTo(map.current)
          }
        } else if (filterCategory === "" && filterCompany === "") {
          item.marker.addTo(map.current)
        }
      }
    })
  }, [filterCategory, filterCompany, allCars])


  useEffect(() => {
    if (isDetailCardShown === false) {
      setCurrentProperty(null)
    }
  }, [isDetailCardShown])

  return (
    <>
      <TopBar title="Map View" />
      <div id="mapContainer" className="map"
        style={{
          height: 'calc(100vh - 120px)', width: '100vw', marginTop: '60px'
        }}
      />
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        overflow: 'scroll',
        paddingRight: '10px',
        position: 'absolute',
        top: '120px'
      }}>
        {
          categories.map((type) => {
            return (
              <p style={{
                fontWeight: 'normal', margin: '0', color: `${type.name === filterCategory ? '#fff' : '#303030'}`, letterSpacing: '2px', background: `${type.name === filterCategory ? ' #404040' : '#fff'}`,
                margin: '5px 5px', borderRadius: '20px', padding: '7px 10px', boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
                border: '1px solid #6d6d6d', display: 'flex', alignItems: 'center'
              }}
                onClick={() => { filterCategory === type.name ? setFilterCategory("") : setFilterCategory(type.name) }}
              ><img src={type.url} height="30px" width="40px" style={{ marginRight: '5px', position: 'relative', top: '-5px' }} />{type.name}</p>
            )
          })
        }
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        overflow: 'scroll',
        paddingRight: '10px',
        position: 'absolute',
        top: '170px'
      }}>
        {
          companies.length !== 0 && companies.map((type) => {
            return (
              <p style={{
                fontWeight: 'normal', margin: '0', color: `${type.name === filterCompany ? '#fff' : '#303030'}`, letterSpacing: '2px', background: `${type.name === filterCompany ? ' #404040' : '#fff'}`,
                margin: '5px 5px', borderRadius: '20px', padding: '7px 10px', boxShadow: '0 0 10px 2px rgb(0 0 0 / 10%)',
                border: '1px solid #6d6d6d', display: 'flex', alignItems: 'center'
              }}
                onClick={() => { filterCompany === type.name ? setFilterCompany("") : setFilterCompany(type.name) }}
              ><img src={type.url} height="30px" width="40px" style={{ marginRight: '5px' }} />{type.name}</p>
            )
          })
        }
      </div>
      {
        isDetailCardShown === true ? <MapDetailCard data={currentProperty} setIsDetailCardShown={setIsDetailCardShown} /> : <></>
      }
    </>
  );
};

export default CreateMap;