import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Carousel,
  Row,
  Col,
  Card,
  Form,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faCar,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import { database } from "../config";
import { toast } from "react-toastify";
import ReadReviews from '../Components/ReadReviews'
import TopBar from "../Components/TopBar";
import OnlyMap from "../Components/map/OnlyMap";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoMdCall } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { HiChat, HiHeart, HiX } from "react-icons/hi";

function SinglePropertyPage(props) {
  const [showVideoModel, setShowVideoModel] = useState(false)
  //Authstate
  const [authState, setAuthState] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [listings, setListings] = useState([]);
  //Booking form states 
  const [propertyKey, setPropertyKey] = useState("");
  const [hostUid, setHostUid] = useState("");
  const [submit, setSubmit] = useState("");
  //Review form states
  const [stars, setStars] = useState("")
  const [review, setReview] = useState("")
  const [profileData, setProfileData] = useState(null)

  const [likes, setLikes] = useState(0)
  const [comments, setComments] = useState(0)
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState(false);
      } else {
        setAuthState(true);
        setUserUid(user.uid);
        database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
          const items = [];
          snapshot.forEach((childSnapshot) => {
            var childData = childSnapshot.val();
            items.push(childData);
          });
          setProfileData(items)
        });
      }
    });
  }, []);

  //get listing data
  useEffect(() => {
    //Retrive key from URL
    const queryString = (props.pagekey ? props.pagekey : window.location.search)
    const RetrivedchildKey = (props.pagekey ? props.pagekey : queryString.substring(1))
    setPropertyKey(RetrivedchildKey);
    database
      .ref("cars")
      .child(RetrivedchildKey)
      .once("value", function (snapshot) {
        const items = [];
        console.log(snapshot.val());
        items.push(
          snapshot.val()
        );
        setListings(items);
      });
    database.ref('car_likes').child(RetrivedchildKey).on('value', (snapshot) => {
      snapshot.val() && setLikes(snapshot.val().like);
    })
    database.ref("comments").child(RetrivedchildKey).on("value", (snapshot) => {
      snapshot.val() && setComments(snapshot.numChildren());
    })
  }, [userUid]);
  //

  const submitReview = (e) => {
    e.preventDefault();
    database.ref("Reviews").push({
      userUid: userUid,
      propertyKey: propertyKey,
      hostUid: hostUid,
      stars: stars,
      review: review,
      name: profileData[0].name,
    });
    toast("Review has been successfullt posted", { type: "success" })
    document.getElementById("review-form").reset();
  };

  //Option values
  function handleChange(event) {
    setStars(event.target.value);
  }


  //Redirect after form submission
  if (submit === "Submitted") {
    return (
      <>
        <Redirect to="/done-booking" />
      </>
    );
  }
  return (
    <div className="home_container" style={{ backgroundColor: '#fff', paddingbottom: '60px', height: 'calc(100vh - 60px)', width: '100vw' }}>
      {
        (
          !props.pagekey ?
            <TopBar title={listings && listings.length !== 0 ? `${listings[0].title}` : ""} />
            :
            <></>
        )
      }
      {listings.map((data) => (
        <>
          <Modal className="single_page_video_modal" show={showVideoModel} style={{ zIndex: '9999999999', padding: '0' }}>
            <video
              autoPlay
              loop
              controls
              src={data.videoOneURL}
              id='video'
              style={{
                height: '-webkit-fill-available',
                width: '-webkit-fill-available',
                objectFit: 'fill'
              }}
            >
            </video>
            <div onClick={() => { setShowVideoModel(false) }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: '10px', top: '10px', height: '50px', width: '50px', borderRadius: '25px', color: '#fff', backgroundColor: '#00000080' }}>
              <HiX style={{ fontSize: '2em' }} />
            </div>
          </Modal>
          <div className="caraousel-slider mr-top-slider">
            <div>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={data.imageOneURL}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={data.imageTwoURL}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={data.imageThreeURL}
                    alt="Third slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={data.imageFourURL}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          <div>
            <Col lg={8} md={8} sm={12} >
              <Card style={{ border: 'none' }}>
                <h5 className="pt-2">{data.title}</h5>
                <p className="text-lead" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ flex: '1' }}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}{data.address}<br />
                    <FontAwesomeIcon icon={faCar} /> {data.category} | {data.company}
                  </div>
                  <div>
                    <Button
                      onClick={() => { setShowVideoModel(true) }}
                      style={{ height: '44px', borderRadius: '22px', backgroundColor: '#42A5F530', padding: '7px 10px', border: 'none', color: '#42A5F5' }}>
                      Watch Video
                    </Button>
                  </div>
                </p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ flex: '.6', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>$ {Number(data.sellingPrice).toLocaleString('en')}</span>
                  </div>
                  <div style={{ marginRight: '5px', flex: '.2', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '25px', height: 'min-content', maxHeight: '50px', width: 'min-content', maxWidth: '100px', backgroundColor: '#42A5F530', padding: '7px 10px' }}>
                    <HiChat style={{ fontSize: '30px', color: '#42A5F5', marginRight: '5px' }} />
                    {comments}
                  </div>
                  <div style={{ flex: '.2', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '25px', height: 'min-content', maxHeight: '50px', width: 'min-content', maxWidth: '100px', backgroundColor: '#EF535030', padding: '7px 10px' }}>
                    <HiHeart style={{ fontSize: '30px', color: '#EF5350', marginRight: '5px' }} />
                    {likes}
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', maxWidth: '500px' }}>
                  <div class="three">
                    <h4>ABOUT THIS CAR</h4>
                  </div>
                  <p className="text-lead">{data.about}</p>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: '0.30', padding: '5px 10px', borderBottom: '1px solid #ddd' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <img src='https://www.cars24.com/js/7b1448ca4a28578b1810e746ec1e2365.svg' style={{ marginRight: '10px' }} />Transmission
                    </div>
                    <p className="mb-0">{data.transmission}</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: '0.30', padding: '5px 10px', borderBottom: '1px solid #ddd' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <img src='https://www.cars24.com/js/eb2a3896599f11d4c9128db3b7ebd9dc.svg' style={{ marginRight: '10px' }} /> Fuel
                    </div>
                    <p className="mb-0">{data.fuel}</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: '0.30', padding: '5px 10px', borderBottom: '1px solid #ddd' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <img src='https://www.cars24.com/js/77dfe6869b0934d82728252b09358190.svg' style={{ marginRight: '10px' }} />Miles Driven
                    </div>
                    <p className="mb-0"> {data.miles}{' '}miles</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: '0.30', padding: '5px 10px', borderBottom: '1px solid #ddd' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <img src='https://www.cars24.com/js/effd086f2c83ace2324375f79ad3db1b.svg' style={{ marginRight: '10px' }} />Owned by
                    </div>
                    <p className="mb-0">{data.noOfOwners < 2 ? `${data.noOfOwners} Owner` : `${data.noOfOwners} Owners`}</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: '0.30', padding: '5px 10px', borderBottom: '1px solid #ddd' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <img src='https://www.cars24.com/js/8d020ad9c969242ee07382259cfd33bd.svg' style={{ marginRight: '10px' }} />Spare Kyes
                    </div>
                    <p className="mb-0">{data.spareKeys}</p>
                  </div>
                </div>
                <div>
                  <div class="three">
                    <h4>LOCATION</h4>
                  </div>
                  <div style={{ width: '100%', height: '200px' }}>
                    <Link to={{ pathname: `/map-view`, state: { fromDashboard: true, lng: data.lngLat.lng, lat: data.lngLat.lat } }}>
                      <OnlyMap lngLat={data.lngLat} />
                    </Link>
                  </div>
                  <div class="three">
                    <h4>FEATURE AND SPECS</h4>
                  </div>
                  <Row>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.ac === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Air Conditioner
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.airBags === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Air Bags
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.leatherSeats === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Leather seats
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.sunRoof === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Sunroof/moonroof
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.heatedSeats === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Heated seats
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.backupCamera === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Backup camera
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.navigationSystem === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Navigation system
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.bluetooth === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Bluetooth
                      </p>
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <p className="text-lead">
                        {data.abs === "Yes" ? <FaRegCheckCircle style={{ fontSize: '1.2em', color: '#4c4', marginRight: '5px' }} /> : <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: '1.2em', color: '#ff4040', marginRight: '5px' }} />}
                        Anti-lock Braking System (ABS)
                      </p>
                    </Col>
                  </Row>
                  <div class="three">
                    <h4>CONTACT SELLER</h4>
                  </div>
                  <Row>
                    <Col sm={12} lg={3} md={3} className="mb-1">
                      <IoMdCall style={{ fontSize: '1.2em', color: '#000', marginRight: '5px' }} />{data?.phoneNumber}
                    </Col>
                    <Col sm={12} lg={3} md={3}>
                      <MdEmail style={{ fontSize: '1.2em', color: '#000', marginRight: '5px' }} />{data.email}
                    </Col>
                  </Row>
                  <hr />
                  <Form onSubmit={submitReview} id="review-form">
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        lg={8}
                        md={8}
                        sm={12}
                        controlId="formBasicText"
                      >
                        <Form.Label>Write Your Review</Form.Label>
                        <Form.Control type="text" placeholder="Write here..." required onChange={(e) => setReview(e.target.value)} />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg={4}
                        md={4}
                        sm={12}
                        controlId="formBasicText"
                      >
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          name="category"
                          onChange={handleChange}
                        >
                          <option>Select</option>
                          <option value="5">5 Star</option>
                          <option value="4">4 Star</option>
                          <option value="3">3 Star</option>
                          <option value="2">2 Star</option>
                          <option value="1">1 Star</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Button variant="success" type="submit">
                      Post Review
                    </Button>

                  </Form>

                  <hr />
                  <ReadReviews propertyKey={propertyKey} />
                  <br />
                </div>
              </Card>
            </Col>
          </div>
          <br />
          <br />
          <br />
        </>
      ))}
    </div>
  );
}
export default React.memo(SinglePropertyPage)