import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container, Card, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import { imageConfig } from '../utils/profileImageConfig'
import { database, storage } from "../config";
import firebase from "firebase";
import { Button as MaterialBtn } from "@material-ui/core";
import { IoAddCircleOutline } from 'react-icons/io5';
import SelectLocation from "../Components/map/SelectLocation";
import OnlyMap from "../Components/map/OnlyMap";


export default function CreateProfile() {


  const [name, setName] = useState("")
  const [about, setAbout] = useState("")

  const [userUid, setUserUid] = useState("")
  const [email, setEmail] = useState("")
  const [thumbnail, setThumbnail] = useState("https://sxprotection.com.au/wp-content/uploads/2016/07/team-placeholder.png")
  const [lngLat, setLngLat] = useState({ lng: -0.11900928568397262, lat: 51.4955567743161 })
  const [address, setAddress] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setShow(true);
  }

  //submit status
  const [submit, setSubmit] = useState("")
  const [profileCheck, setProfileCheck] = useState("")

  //Authstate
  const [authState, setAuthState] = useState("");

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState("Logged-out")
      } else {
        setAuthState("Logged-in")
        setUserUid(user.uid)
        setEmail(user.email)
        setName(user.displayName)
      }
    });
  }, [])

  //image 1 function
  async function uploadProfilePicture(e) {

    const imageFile = e.target.files[0];

    try {
      const compressedFile1 = await imageCompression(imageFile, imageConfig);

      await uploadToServer(compressedFile1); // write your own logic

      function uploadToServer() {
        const imageOne = compressedFile1;
        const uploadTask = storage.ref(`profile-pictures/${imageOne.name}`).put(imageOne);
        uploadTask.on(
          "STATE_CHANGED",
          (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            toast("Profile Picture Upload in Progress:Please Wait", { type: "warning", toastId: "1", });
            if (progress === 100) {
              toast.update("1", {
                render: "Image Successfully Uploaded",
                type: "success",
                autoClose: 5000
              });
            }
          },
          (error) => {
            console.log(error);
            toast(error, { type: "error" })
          },
          () => {
            storage
              .ref("profile-pictures")
              .child(imageOne.name)
              .getDownloadURL()
              .then((url) => {
                setThumbnail(url);
              });
          }
        );
      }

    } catch (error) {
      toast(error, { type: "error" })
    }
  };

  //submission
  const handleSubmit = (e) => {
    e.preventDefault();
    database.ref("My-Profile").push({
      name: name,
      about: about,
      lngLat: lngLat,
      email: email,
      userUid: userUid,
      thumbnail: thumbnail,
      address: address,
    });
    setSubmit("Submitted")
  };

  //check
  useEffect(() => {
    database.ref("My-Profile").orderByChild("userUid").equalTo(userUid).once("value", (snapshot) => {
      if (snapshot.exists()) {
        setProfileCheck(true)
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [handleSubmit])
  //



  if (profileCheck === true) {
    return (
      <>
        <Redirect to="/" />
      </>
    )
  }


  //Redirect after form submission
  if (submit === "Submitted") {
    return (
      <>
        <Redirect to="/" />
      </>
    )
  }


  if (authState === "Logged-out") {
    return (
      <>
        <Redirect to="/" />
      </>
    )
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />

      <Container className="home_container" style={{ backgroundColor: '#fff' }}>
        <Card>
          <Card.Header className="text-center card-title card-header-create-profile">Create Your Profile</Card.Header>
          <Row style={{ marginTop: '10px' }}>
            <div style={{ width: '100%' }}>
              <center>
                <img src={thumbnail} style={{ height: '150px', width: '150px', borderRadius: '75px' }} />
              </center>
            </div>
          </Row>
          <Form.Row>
            <Form.Group as={Col} lg={12} md={12} sm={12} className="file-input">
              <Form.Control type="file" onChange={uploadProfilePicture} required />
              <center>
                <MaterialBtn color="primary" style={{ width: 'max-content', marginTop: '10px' }}>
                  <IoAddCircleOutline style={{ fontSize: '1.5em', marginRight: '10px' }} />Add Profile Picture
                </MaterialBtn>
              </center>
            </Form.Group>
          </Form.Row>
          <Card.Body>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <Form onSubmit={handleSubmit}>
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column sm={2}>
                      Name
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control required type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column sm={2}>
                      About
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control required type="text" placeholder="Say something about you..." value={about} onChange={(e) => setAbout(e.target.value)} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formGridCity">
                    <Form.Label column sm={2}>
                      Address
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control required type="text" placeholder="Your Current Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </Col>
                  </Form.Group>
                  <Form.Row className="mb-3" style={{ padding: '0px 5px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div style={{ width: '100%', height: '200px' }} onClick={() => { handleShow() }}>
                      <OnlyMap lngLat={lngLat} />
                    </div>
                    <MaterialBtn variant="contained" color="primary" onClick={() => { handleShow() }} style={{ borderRadius: '0px' }}>
                      Select Location
                    </MaterialBtn>
                    <Modal show={show} onHide={handleClose}>
                      <SelectLocation handleClose={handleClose} setLngLat={setLngLat} />
                    </Modal>
                  </Form.Row>
                  <hr />
                  <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                      <center>
                        <Button type="submit">Create</Button>
                      </center>
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}
