import firebase from 'firebase'
import "firebase/storage"

var firebaseConfig = {
  apiKey: "AIzaSyBX1YrnnLXWfusboMALIDOtZ9O3ro5eF40",
  authDomain: "Autovid-5b304.firebaseapp.com",
  projectId: "Autovid-5b304",
  storageBucket: "Autovid-5b304.appspot.com",
  messagingSenderId: "568609520090",
  appId: "1:568609520090:web:687f7ecf9a4780a57828e3",
  measurementId: "G-4BCSSY79LW"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var database = firebase.database();

const auth = firebase.auth();

export const storage = firebase.storage();

export { auth };

export { database };
