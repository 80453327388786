import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
} from "react-bootstrap";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import { HiChat, HiHeart, HiOutlineHeart } from 'react-icons/hi'
import firebase from "firebase";
import { database } from "../config";

export default function MyListings() {
  const [show, setShow] = useState(false);
  const [deleteKey, setDeleteKey] = useState("")

  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setDeleteKey(key)
    setShow(true);
  }

  //Authstate
  const [authState, setAuthState] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [listingsCheck, setListingsCheck] = useState(null);
  //snapshots
  const [listings, setListings] = useState([]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState(false);
      } else {
        setAuthState(true);
        setUserUid(user.uid);
        database
          .ref("cars")
          .orderByChild("userUid")
          .equalTo(user.uid)
          .once("value", (snapshot) => {
            if (snapshot.exists()) {
              setListingsCheck(true);

            } else {
              setListingsCheck(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }, []);


  //get listing data
  useEffect(() => {
    database
      .ref("cars")
      .orderByChild("userUid")
      .equalTo(userUid)
      .on("value", (snapshot) => {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childKey = childSnapshot.key;
          var data = childSnapshot.val();
          items.push({
            key: childKey,
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber,
            category: data.category,
            company: data.company,
            modelName: data.modelName,
            address: data.address,
            title: data.title,
            about: data.about,
            sellingPrice: data.sellingPrice,
            miles: data.miles,
            seats: data.seats,
            transmission: data.transmission,
            fuel: data.fuel,
            noOfOwners: data.noOfOwners,
            ac: data.ac,
            airBags: data.airBags,
            leatherseats: data.leatherseats,
            sunroof: data.sunroof,
            heatedSeats: data.heatedSeats,
            backupCamera: data.backupCamera,
            navigationSystem: data.navigationSystem,
            bluetooth: data.bluetooth,
            abs: data.abs,
            userUid: data.userUid,
            videoOneURL: data.videoOneURL,
            imageOneURL: data.imageOneURL,
            imageTwoURL: data.imageTwoURL,
            imageThreeURL: data.imageThreeURL,
            imageFourURL: data.imageFourURL,
            lngLat: data.lngLat,
          });
        });
        setListings(items);
      });
  }, [userUid]);
  //

  return (
    <div>
      {listingsCheck === true ? <h2 className="text-center">My Cars</h2> : ""}
      <div className="featured_card_grid_container" style={{ padding: '0px 20px' }}>
        {listings.map((data, id) => (
          <div className="featured_card_container" >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <img
                src={data.imageOneURL}
                style={{ borderRadius: '10px 10px 0px 0px', height: '200px', width: '100%' }}
              />
              <div style={{ width: '100%', color: '#000', padding: '15px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ flex: '.6', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <span style={{ fontSize: '1.4em', fontWeight: 'bold' }}>$ {Number(data.sellingPrice).toLocaleString('en')}</span>
                    <span><FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon> {String(data.address).slice(0, 20)} </span>
                  </div>
                  <div style={{ flex: '.2', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '25px', maxHeight: '50px', maxWidth: '50px', backgroundColor: '#ff000010' }}>
                    <Button variant="danger" onClick={(e) => { handleShow(data.key) }}  >
                      Delete
                    </Button>
                  </div>
                </div>
                <hr style={{ margin: '10px 0px' }} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '12px' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#42A5F530' }}>
                    <img src='https://www.cars24.com/js/7b1448ca4a28578b1810e746ec1e2365.svg' />{data.transmission}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#42A5F530' }}>
                    <img src='https://www.cars24.com/js/eb2a3896599f11d4c9128db3b7ebd9dc.svg' /> {data.fuel}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: '0.30', borderRadius: '10px', padding: '5px 10px', backgroundColor: '#42A5F530' }}>
                    <img src='https://www.cars24.com/js/77dfe6869b0934d82728252b09358190.svg' /> {data.miles}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>DevBud</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                const propertyDB = firebase
                  .database()
                  .ref("cars")
                  .child(deleteKey);
                propertyDB.remove();
                handleClose();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
} 