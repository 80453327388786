import React from "react";
import { storage } from "../../config";
import { useDropzone } from "react-dropzone";
import { Button } from "@material-ui/core";
import { IoAddCircleOutline } from 'react-icons/io5';
export default function ImagesDropzone({ setImageList }) {
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles.length <= 4) {
            const newImages = Array.from(acceptedFiles).map((file) => {
                return {
                    file: file,
                    fileName: file.name,
                    status: "CREATED",
                    storageRef: storage.ref().child(file.name),
                    downloadURL: "",
                    description: "",
                };
            });
            setImageList((prevState) => [...prevState, ...newImages]);
        } else {
            alert('please provide only four photos')
        }
    };

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: "image/png, image/jpeg",
        noClick: true,
        noKeyboard: true,
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button onClick={open} variant="contained" style={{ width: 'max-content', backgroundColor: '#007bff' }}>
                <IoAddCircleOutline style={{ fontSize: '1.5em', marginRight: '10px' }} />Add Image
            </Button>
        </div>
    );
}
