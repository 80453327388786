import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Container,
    Card,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { database } from "../config";

export default function ReadComments(props) {

    //snapshots
    const [comments, setComments] = useState([]);
    //get listing data
    useEffect(() => {
        database
            .ref("comments")
            .child(props.propertyKey)
            .on("value", (snapshot) => {
                const items = [];
                snapshot.forEach((childSnapshot) => {
                    items.push(childSnapshot.val());
                });
                setComments(items.reverse());
            });
    }, []);

    return (
        <>
            <Container>
                <Row>
                    {comments.map((data) => {
                        return (
                            <Col sm={12} md={12} lg={12} key={uuidv4()}>
                                <Card className="mt-2">
                                    <Card.Body>
                                        <Card.Title className="text-dark">{data.name}</Card.Title>
                                        <Card.Text className="text-dark">
                                            {data.comment}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            <br />
            <br />
        </>
    );
}
