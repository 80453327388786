import React, { useEffect, useState } from 'react';
import CategoriesSection from '../Components/CategoriesSection';
import MyListings from '../Components/featuredSection';
import CreateSmallMap from '../Components/map/CreateSmallMap';
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { database } from "../config";


function Explore() {
    const [authState, setAuthState] = useState("");
    const [lngLat, setLngLat] = useState(null)

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState("Logged-out")
            } else {
                setAuthState("Logged-in")
                database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
                    let location = [];
                    snapshot.forEach((child) => {
                        location.push(child.val().lngLat)
                    })
                    setLngLat(location[0])
                });
            }
        });
    }, []);
    if (authState === "Logged-out") {
        return (
            <>
                <Redirect to="/" />
            </>
        )
    } else {
        return (
            <div className='explore_container'>
                <div className='small_map_container'>
                    <p style={{ color: 'black', fontSize: '1.1em', fontWeight: '600', marginBottom: '0px', marginTop: '10px' }}>Find Cars Near You</p>
                    <div className='small_map' style={{ margin: '10px 0px' }}>
                        {
                            lngLat && <CreateSmallMap lngLat={lngLat} />
                        }
                    </div>
                </div>
                <CategoriesSection />
                <MyListings />
            </div>
        )
    }

}

export default Explore