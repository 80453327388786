import React, { useEffect } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax 
import { FaExpandArrowsAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

const OnlyMap = (props) => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWVudXRvayIsImEiOiJjbGMzeG1kNHgwbm9lNDFuemkzaXduazgxIn0.alLoPOMYiPcZtHmA9EaLZQ';
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/streets-v11",
            center: props.lngLat,
            zoom: 10,
            interactive: false
        });
        const marker = new mapboxgl.Marker({ color: 'red' })
            .setLngLat(props.lngLat)
            .addTo(map)
    }, [props.lngLat]);
    return (
        <div id="mapContainer" className="map" style={{ height: '100%', width: '100%' }}>
        </div>
    );
};

export default OnlyMap;