import React from "react";

import {
    Paper,
    Grid,
    CircularProgress,
    Box,
    IconButton,
} from "@material-ui/core";
import { RiDeleteBin5Line } from "react-icons/ri";
export default function ImageElement({ image, index, handleDeleteImage }) {

    return (
        <Box my={2} width={400} style={{ margin: '10px', width: '75vw' }}>
            <Paper>
                <Grid container direction="row" justify="center" >
                    <Grid item container alignItems="center" justify="center">
                        {image.downloadURL ? (
                            <div style={{ position: 'relative', flex: '1' }}>
                                <img
                                    src={image.downloadURL}
                                    alt={`Upload Preview ${index + 1}`}
                                    style={{
                                        height: "300px",
                                        width: "100%", backgroundColor: '#000',
                                    }}
                                />
                                <IconButton
                                    aria-label="Delete Image"
                                    onClick={() => handleDeleteImage(index)}
                                    style={{
                                        position: 'absolute',
                                        color: 'red',
                                        height: '60px',
                                        width: '60px',
                                        borderRadius: '30px',
                                        backgroundColor: '#ff000040',
                                        top: '20px',
                                        right: '20px'
                                    }}
                                >
                                    <RiDeleteBin5Line />
                                </IconButton>
                            </div>

                        ) : (
                            <Box p={2} style={{
                                height: "300px",
                                width: "100%",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#000'
                            }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}