import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { database } from "../config";


export const categories = [
  {
    name: 'Sedan',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_sedan.png',
    linkTo: '/map-view',
  },
  {
    name: 'Coupe',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_coupe.png',
    linkTo: '/map-view',
  },
  {
    name: 'Hatchback',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_hatchback.png',
    linkTo: '/map-view',
  },
  {
    name: 'Wagon',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_wagon.png',
    linkTo: '/map-view',
  },
  {
    name: 'Van/Minivan',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_van.png',
    linkTo: '/map-view',
  },
  {
    name: 'Pickup',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_truck.png',
    linkTo: '/map-view',
  },
  {
    name: 'Convertible',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_convertible.png',
    linkTo: '/map-view',
  },
  {
    name: 'SUV',
    url: '//www.autotrader.com/content/dam/autotrader/homepage/styles/new_default_desk_style_suv.png',
    linkTo: 'explore/Search?SUV',
  }
]
// export const companies = [
//   {
//     name: 'Ford',
//     url: 'https://www.carlogos.org/car-logos/ford-logo.png',
//     linkTo: '/map-view',
//   },
//   {
//     name: 'Honda',
//     url: 'https://www.carlogos.org/car-logos/honda-logo.png',
//     linkTo: '/map-view',
//   },
//   {
//     name: 'Nissan',
//     url: 'https://www.carlogos.org/car-logos/nissan-logo.png',
//     linkTo: '/map-view',
//   },
//   {
//     name: 'Tesla',
//     url: 'https://www.carlogos.org/car-logos/tesla-logo.png',
//     linkTo: '/map-view',
//   }, {
//     name: 'BMW',
//     url: 'https://www.carlogos.org/car-logos/bmw-logo.png',
//     linkTo: '/map-view',
//   }, {
//     name: 'Toyota',
//     url: 'https://www.carlogos.org/car-logos/toyota-logo.png',
//     linkTo: '/map-view',
//   }, {
//     name: 'Audi',
//     url: 'https://www.carlogos.org/car-logos/audi-logo.png',
//     linkTo: '/map-view',
//   }, {
//     name: 'Hyundai',
//     url: 'https://www.carlogos.org/car-logos/hyundai-logo.png',
//     linkTo: '/map-view',
//   }, {
//     name: 'Chevrolet',
//     url: 'https://www.carlogos.org/car-logos/chevrolet-logo.png',
//     linkTo: '/map-view',
//   }, {
//     name: 'Volkswagen',
//     url: 'https://www.carlogos.org/car-logos/volkswagen-logo.png',
//     linkTo: '/map-view',
//   }, {
//     name: 'Mercedes',
//     url: 'https://www.carlogos.org/car-logos/mercedes-benz-logo.png',
//     linkTo: '/map-view',
//   }
// ]

// export const locations = [
//   {
//     title: 'LONDON',
//     imgURL: 'https://image-tc.galaxy.tf/wipng-4cdjzpts0hwpzp38ghrz1p56r/standard.png?crop=76%2C0%2C873%2C655',
//     linkTo: '/map-view',
//     coordinates: { lng: -0.11900928568397262, lat: 51.4955567743161 }
//   },
//   {
//     title: 'LIVERPOOL',
//     imgURL: 'https://i2-prod.liverpoolecho.co.uk/incoming/article17828599.ece/ALTERNATES/s615/16_The-Churchill-Way-Flyover-and-citys-changing-skyline-from-the-Radio-City-TowerPic-Andrew-Teebay.jpg',
//     linkTo: '/map-view',
//     coordinates: { lng: -2.998300801403815, lat: 53.403447690413685 }
//   },
//   {
//     title: 'NEW YORK',
//     imgURL: 'https://www.nationsonline.org/gallery/USA/NYC-Downtown-and-Statue-of-Liberty.jpg',
//     linkTo: '/map-view',
//     coordinates: { lng: -74.00407607940897, lat: 40.711943714919926 }
//   },
//   {
//     title: 'MUMBAI',
//     imgURL: 'https://images.moneycontrol.com/static-mcnews/2018/06/RTX2WBGB.jpg?impolicy=website&width=1600&height=900',
//     linkTo: '/map-view',
//     coordinates: { lng: 72.88148278398259, lat: 19.083646708639748 }
//   }
// ]
export default function CategoriesSection() {
  const [companies, setCompanies] = useState([])
  const [locations, setLocations] = useState([])
  useEffect(() => {
    database.ref("companies").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          items.push(childData);
        });
        setCompanies(items)
      }
    }).catch((error) => {
      console.error(error);
    });
    database.ref("locations").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          items.push(childData);
        });
        setLocations(items)
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [])
  return (
    <div>
      <div>
        {/* <p style={{ color: 'black', fontSize: '1.1em', fontWeight: '600', margin: '0px' }}>Top Locations</p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100vw - 20px)',
          overflow: 'scroll',
          padding: '10px 5px'
        }}>
          {
            locations.length !== 0 && locations.map((location) => {
              return (
                <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0', height: '150px' }}>
                  <Link to={{ pathname: `${location.linkTo}`, state: { fromDashboard: true, lng: location.coordinates.lng, lat: location.coordinates.lat } }}>
                    <Card className="category-cards" >
                      <Card.Img variant="top" src={location.imgURL} className="category-img" style={{ height: '100%', width: '100%', borderRadius: '10px' }} />
                      <Card.Body style={{ position: 'absolute', bottom: '0', width: '100%', backgroundImage: 'linear-gradient(360deg, black, transparent)', borderRadius: '10px' }}>
                        <p style={{ fontWeight: 'normal', margin: '0', color: 'white', letterSpacing: '2px' }}>{location.title}</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )
            })
          }
        </div> */}
        <p style={{ color: 'black', fontSize: '1.1em', fontWeight: '600', margin: '0px' }}>Browse by category</p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100vw - 20px)',
          overflow: 'scroll',
          padding: '10px 5px'
        }}>
          {
            categories.map((type) => {
              return (
                <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0', height: '150px' }} >
                  <Link to={{ pathname: `${type.linkTo}`, state: { fromDashboard: true, category: type.name } }}>
                    <Card className="category-cards" >
                      <Card.Img variant="top" src={type.url} className="category-img" style={{ height: '100%', width: '100%', borderRadius: '10px' }} />
                      <Card.Body style={{ position: 'absolute', top: '0', width: '100%', borderRadius: '10px' }}>
                        <p style={{ fontWeight: 'normal', margin: '0', color: '#000', letterSpacing: '2px' }}>{type.name}</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )
            })
          }
        </div>
        <p style={{ color: 'black', fontSize: '1.1em', fontWeight: '600', margin: '0px' }}>Browse by companies</p>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: 'calc(100vw - 20px)',
          overflow: 'scroll',
          padding: '10px 5px'
        }}>
          {
            companies.length !== 0 && companies.map((type) => {
              return (
                <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0', height: '150px' }}  >
                  <Link to={{ pathname: `${type.linkTo}`, state: { fromDashboard: true, company: type.name } }}>
                    <Card className="category-cards" style={{ height: '150px', width: '140px' }}>
                      <Card.Img src={type.url} className="category-img" style={{ position: 'absolute', top: '10px', left: 'calc(50% - 65px)', height: '100px', width: '130px', borderRadius: '10px' }} />
                      <Card.Body style={{ position: 'absolute', bottom: '0', width: '100%', borderRadius: '10px' }}>
                        <p style={{ fontWeight: 'normal', margin: '0', color: '#000', letterSpacing: '2px' }}>{type.name}</p>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}
