import React, { useState, useEffect } from "react";

import { Grid, Box } from "@material-ui/core";

import ImagesDropzone from "./imagesDropzone";
import ImageElement from "./imageElement";

export default function App({
    setImageOneURL,
    setImageTwoURL,
    setImageThreeURL,
    setImageFourURL
}) {
    const [imageList, setImageList] = useState([]);

    const handleDeleteImage = (index) => {
        imageList[index].storageRef
            .delete()
            .then(() => {
                const newArray = [...imageList];
                newArray.splice(index, 1);
                setImageList(newArray);
            })
            .catch((error) => {
                console.log("Error deleting file:", error);
            });
        if (index === 0) {
            setImageOneURL("")
        } else if (index === 1) {
            setImageTwoURL("")
        } else if (index === 2) {
            setImageThreeURL("")
        } else if (index === 3) {
            setImageFourURL("")
        }
    };

    const changeImageField = (index, parameter, value) => {
        const newArray = [...imageList];
        newArray[index][parameter] = value;
        setImageList(newArray);
    };

    useEffect(() => {
        imageList.forEach((image, index) => {
            if (image.status === "FINISH" || image.status === "UPLOADING") return;
            changeImageField(index, "status", "UPLOADING");
            const uploadTask = image.storageRef.put(image.file);
            uploadTask.on(
                "state_changed",
                null,
                function error(err) {
                    console.log("Error Image Upload:", err);
                },
                async function complete() {
                    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                    if (index === 0) {
                        setImageOneURL(downloadURL)
                    } else if (index === 1) {
                        setImageTwoURL(downloadURL)
                    } else if (index === 2) {
                        setImageThreeURL(downloadURL)
                    } else if (index === 3) {
                        setImageFourURL(downloadURL)
                    }
                    changeImageField(index, "downloadURL", downloadURL);
                    changeImageField(index, "status", "FINISH");
                }
            );
        });
    });

    return (
        <Grid container direction="column" alignItems="center" spacing={2}>
            {imageList.length > 0 && (
                <Box bgcolor="primary.light" style={{ width: 'calc(100vw - 50px)', overflow: 'scroll', display: 'flex', flexDirection: 'row' }}>
                    {imageList.map((image, index) => {
                        return (
                            <Grid item key={image.file.size + index} >
                                <ImageElement
                                    image={image}
                                    index={index}
                                    handleDeleteImage={handleDeleteImage}
                                />
                            </Grid>
                        );
                    })}
                </Box>
            )}
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                xs={12}
                spacing={1}
            >
                {
                    imageList.length < 4 ?
                        <Grid item container xs={12} justify="center">
                            <ImagesDropzone setImageList={setImageList} />
                        </Grid>
                        :
                        <Grid item container xs={12} justify="center">
                            <p>Only 4 photos are allowed</p>
                        </Grid>
                }
            </Grid>
        </Grid>
    );
}
